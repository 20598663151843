import React, { Component } from 'react';
import Hb from '../../images/header-button.png';

const Header = () => {
    return (
            <>
                <h1>Ароматы на удачу</h1>
                <h2>Выиграйте iPhone 15 и другие подарки</h2>
                <p>с 12 февраля по 31 марта 2024 г.</p>
                <a href="#check"><img src={Hb}/></a>
            </>
            );
}

export default Header