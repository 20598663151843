import React, { Component, useState } from 'react';
import { TextField, Tooltip, Button } from '@mui/material';
import InputMask from "react-input-mask";
import CheckImg from '../../images/check_img.png';
import Message from '../modal/message';

const Check = (props) => {
    const [regCheck, setRegCheck] = useState({
        f: "",
        i: "",
        email: "",
        phone: "",
        is_agree_rules: false,
        is_agree_pers: false,
        file: ""
    })

    const [open, setOpen] = useState(false);
    const isMobile = window.innerWidth <= 1023;
    const [showModalMessage, setShowModalMessage] = useState(false)
    const [modalMessageText, setModalMessageText] = useState("")
    const [modalMessageType, setModalMessageType] = useState(1)

    function handleChange(event) {
        const regEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const name = event.target.name;
        const value = event.target.value;
        const id = event.target.id;
        const files = event.target.files;
        if (name === 'f') {
            if (value === '') {
                document.getElementById('regCheck-block-' + name).classList.add('error');
                setRegCheck({...regCheck, [name]: value})
            } else {
                document.getElementById('regCheck-block-' + name).classList.remove('error');
                setRegCheck({...regCheck, [name]: value})
            }
        } else if (name === 'i') {
            if (value === '') {
                document.getElementById('regCheck-block-' + name).classList.add('error');
                setRegCheck({...regCheck, [name]: value})
            } else {
                document.getElementById('regCheck-block-' + name).classList.remove('error');
                setRegCheck({...regCheck, [name]: value})
            }
        }
        if (name === 'email') {
            if (value === '' || regEmail.test(value) === false) {
                document.getElementById('regCheck-block-' + name).classList.add('error');
                setRegCheck({...regCheck, [name]: value})
            } else {
                document.getElementById('regCheck-block-' + name).classList.remove('error');
                setRegCheck({...regCheck, [name]: value})
            }
        } else if (name === 'phone') {
            if (value === '') {
                document.getElementById('regCheck-block-' + name).classList.add('error');
                setRegCheck({...regCheck, [name]: value})
            } else {
                document.getElementById('regCheck-block-' + name).classList.remove('error');
                setRegCheck({...regCheck, [name]: value})
            }
        } else if (id == 'is_agree_rules') {
            if (regCheck.is_agree_rules === false) {
                document.getElementById('regCheck-block-' + id).classList.remove('error')
                setRegCheck({...regCheck, [id]: true})
            } else {
                document.getElementById('regCheck-block-' + id).classList.add('error')
                setRegCheck({...regCheck, [id]: false})
            }
        } else if (id == 'is_agree_pers') {
            if (regCheck.is_agree_pers === false) {
                document.getElementById('regCheck-block-' + id).classList.remove('error')
                setRegCheck({...regCheck, [id]: true})
            } else {
                document.getElementById('regCheck-block-' + id).classList.add('error')
                setRegCheck({...regCheck, [id]: false})
            }
        } else if (name === 'file') {
            if (files[0]) {
                setRegCheck({...regCheck, [name]: files[0]})
                document.getElementById('block-file').classList.remove('error');
            } else {
                document.getElementById('block-file').classList.add('error');
            }
        }
    }

    function handleSubmit() {
//        if (regCheck.f === "") {
//            document.getElementById('regCheck-block-f').classList.add('error');
//        }
//        if (regCheck.i === "") {
//            document.getElementById('regCheck-block-i').classList.add('error');
//        }
//        if (regCheck.email === "") {
//            document.getElementById('regCheck-block-email').classList.add('error');
//        }
//        if (regCheck.phone === "") {
//            document.getElementById('regCheck-block-phone').classList.add('error');
//        }
//        if (regCheck.is_agree_rules === false) {
//            document.getElementById('regCheck-block-is_agree_rules').classList.add('error');
//        }
//        if (regCheck.is_agree_pers === false) {
//            document.getElementById('regCheck-block-is_agree_pers').classList.add('error');
//        }
//        if (regCheck.file === "") {
//            document.getElementById('block-file').classList.add('error');
//        }
//        if (
//                !document.getElementById('regCheck-block-f').classList.contains('error')
//                && !document.getElementById('regCheck-block-i').classList.contains('error')
//                && !document.getElementById('regCheck-block-email').classList.contains('error')
//                && !document.getElementById('regCheck-block-phone').classList.contains('error')
//                && !document.getElementById('regCheck-block-is_agree_rules').classList.contains('error')
//                && !document.getElementById('regCheck-block-is_agree_pers').classList.contains('error')
//                && !document.getElementById('block-file').classList.contains('error')
//                ) {
//
//            const formData = new FormData();
//
//            formData.append('f', regCheck.f)
//            formData.append('i', regCheck.i)
//            formData.append('email', regCheck.email)
//            formData.append('phone', regCheck.phone)
//            formData.append('is_agree_rules', regCheck.is_agree_rules)
//            formData.append('is_agree_pers', regCheck.is_agree_pers)
//            formData.append('file', regCheck.file)
//
//            fetch(props.urlServer + "/json/form/registration", {
//                method: "POST",
//                credentials: "include",
//                body: formData
//            }).then((response) => {
//                return response.json();
//            }).then((resp) => {
//                if (resp.result === 1) {
//                    document.getElementById('regCheck-f').value = "";
//                    document.getElementById('regCheck-f').value = null;
//                    document.getElementById('regCheck-i').value = "";
//                    document.getElementById('regCheck-i').value = null;
//                    document.getElementById('regCheck-email').value = "";
//                    document.getElementById('regCheck-email').value = null;
//                    document.getElementById('regCheck-phone').value = "";
//                    document.getElementById('regCheck-phone').value = null;
//                    document.getElementById('is_agree_rules').value = "";
//                    document.getElementById('is_agree_rules').value = null;
//                    document.getElementById('is_agree_pers').value = "";
//                    document.getElementById('is_agree_pers').value = null;
//                    document.getElementById('file').value = "";
//                    document.getElementById('file').value = null;
//                    document.getElementById('file').files = null;
//
//                    setRegCheck({
//                        f: "",
//                        i: "",
//                        email: "",
//                        phone: "",
//                        is_agree_rules: false,
//                        is_agree_pers: false,
//                        file: ""
//                    })
//                    setModalMessageType(1)
//                    setShowModalMessage(true)
//                    setModalMessageText(resp.msg)
//                }
//            }, (error) => {
//
//            })
//        }

                    setModalMessageType(1)
                    setShowModalMessage(true)
                    setModalMessageText("Регистрация чеков завершена 31.03.2024.")

    }

    function handleCloseModalMessage() {
        if (modalMessageType === 1) {
            setModalMessageText("")
            setShowModalMessage(false)
        } else {
            setModalMessageText("")
            setShowModalMessage(false)
        }
    }

    if (isMobile) {
        return (
                <div className="row">
                    <h1>РЕГИСТРАЦИЯ ЧЕКА</h1>
                    <div className="col-lg-6">
                        <h2>Как сфотографировать чек?</h2>
                        <p>Чек должен быть сфотографирован полностью, включая верхний и нижний края чека. Если длина чека не позволяет качественно сфотографировать его полностью, чек необходимо сложить таким образом, чтобы обязательно была видна следующая информация:</p>
                        <img src={CheckImg}/>
                    </div>
                    <div className="col-lg-6">
                        <div className="block-input" id="regCheck-block-f">
                            <TextField label="Фамилия" variant="standard" id="regCheck-f" name="f" value={regCheck.f} onChange={handleChange} onClick={handleChange}/>
                        </div>
                        <div className="block-input" id="regCheck-block-i">
                            <TextField label="Имя" variant="standard" id="regCheck-i" name="i" value={regCheck.i} onChange={handleChange} onClick={handleChange}/>
                        </div>
                        <div className="block-input" id="regCheck-block-email">
                            <TextField label="E-mail" variant="standard" id="regCheck-email" name="email" value={regCheck.email} onChange={handleChange} onClick={handleChange}/>
                        </div>
                        <div className="block-input" id="regCheck-block-phone">
                            <InputMask mask="+7(999)999-99-99" value={regCheck.phone} onChange={handleChange}>
                                {() => <TextField label="Номер телефона" variant="standard" id="regCheck-phone" name="phone" />}
                            </InputMask>
                        </div>
                        <div className="block-checkbox" id="regCheck-block-is_agree_rules">
                            <div className="checkbox">
                                <input type="checkbox" checked={regCheck.is_agree_rules} id="is_agree_rules" onChange={handleChange}/>
                                <label id="checkbox-is_agree_rules"></label>
                            </div>                                        
                            <label>Я ознакомлен и согласен с <a href="/images/rules.pdf" target="_blank">Правилами акции</a></label>
                        </div>
                        <div className="block-checkbox" id="regCheck-block-is_agree_pers">
                            <div className="checkbox">
                                <input type="checkbox" checked={regCheck.is_agree_pers} id="is_agree_pers" onChange={handleChange}/>
                                <label id="checkbox-is_agree_pers"></label>
                            </div>
                            <label>Я ознакомлен и согласен с <a href="/images/pnd.pdf" target="_blank">Политикой обработки персональных данных</a></label>
                        </div>
                        <div id="block-file">
                            <div className="file">
                                <input type="file" id="file" name="file" onChange={handleChange} onClick={handleChange}/>
                                <label>
                                    <p className={regCheck.file ? 'fileName' : ''} dangerouslySetInnerHTML={{__html: regCheck.file ? regCheck.file.name : 'Прикрепить чек'}}></p>
                                </label>
                            </div>
                        </div>
                        <Button variant="contained" type="submit" onClick={handleSubmit}>ОТПРАВИТЬ</Button>
                    </div>
                    <Message showModalMessage={showModalMessage} modalMessageText={modalMessageText} handleCloseModalMessage={handleCloseModalMessage}/>
                </div>
                )
    } else {
        return (
                <div className="row">
                    <h1>РЕГИСТРАЦИЯ ЧЕКА</h1>
                    <div className="col-md-6">
                        <div className="block-input" id="regCheck-block-f">
                            <TextField label="Фамилия" variant="standard" id="regCheck-f" name="f" value={regCheck.f} onChange={handleChange} onClick={handleChange}/>
                        </div>
                        <div className="block-input" id="regCheck-block-i">
                            <TextField label="Имя" variant="standard" id="regCheck-i" name="i" value={regCheck.i} onChange={handleChange} onClick={handleChange}/>
                        </div>
                        <div className="block-input" id="regCheck-block-email">
                            <TextField label="E-mail" variant="standard" id="regCheck-email" name="email" value={regCheck.email} onChange={handleChange} onClick={handleChange}/>
                        </div>
                        <div className="block-input" id="regCheck-block-phone">
                            <InputMask mask="+7(999)999-99-99" value={regCheck.phone} onChange={handleChange}>
                                {() => <TextField label="Номер телефона" variant="standard" id="regCheck-phone" name="phone" />}
                            </InputMask>
                        </div>
                        <div className="block-checkbox" id="regCheck-block-is_agree_rules">
                            <div className="checkbox">
                                <input type="checkbox" checked={regCheck.is_agree_rules} id="is_agree_rules" onChange={handleChange}/>
                                <label id="checkbox-is_agree_rules"></label>
                            </div>                                        
                            <label>Я ознакомлен и согласен с <a href="/images/rules.pdf" target="_blank">Правилами акции</a></label>
                        </div>
                        <div className="block-checkbox" id="regCheck-block-is_agree_pers">
                            <div className="checkbox">
                                <input type="checkbox" checked={regCheck.is_agree_pers} id="is_agree_pers" onChange={handleChange}/>
                                <label id="checkbox-is_agree_pers"></label>
                            </div>
                            <label>Я ознакомлен и согласен с <a href="/images/pdn.pdf" target="_blank">Политикой обработки персональных данных</a></label>
                        </div>
                        <div id="block-file">
                            <div className="file">
                                <input type="file" id="file" name="file" onChange={handleChange} onClick={handleChange}/>
                                <label>
                                    <p className={regCheck.file ? 'fileName' : ''} dangerouslySetInnerHTML={{__html: regCheck.file ? regCheck.file.name : 'Прикрепить чек'}}></p>
                                </label>
                            </div>
                        </div>
                        <Button variant="contained" type="submit" onClick={handleSubmit}>ОТПРАВИТЬ</Button>
                    </div>
                    <div className="col-md-6">
                        <h2>Как сфотографировать чек?</h2>
                        <p>Чек должен быть сфотографирован полностью, включая верхний и нижний края чека. Если длина чека не позволяет качественно сфотографировать его полностью, чек необходимо сложить таким образом, чтобы обязательно была видна следующая информация:</p>
                        <img src={CheckImg}/>
                    </div>
                    <Message showModalMessage={showModalMessage} modalMessageText={modalMessageText} handleCloseModalMessage={handleCloseModalMessage}/>
                </div>
                )
    }
}

export default Check