import React, { Component, useState, useEffect, useContext, useRef } from 'react';
import {Dialog, DialogContent, Button, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, styled, Paper, AppBar, Tabs, Tab, Box, Typography, Tooltip, TextField } from "@mui/material";
import { Link } from 'react-router-dom';
import Moment from 'moment';
import InputMask from "react-input-mask";
import ReactPlayer from 'react-player';
import { AbonentContext } from './content';
import Header from '../header/header';
import Prizes from '../prizes/prizes';
import Rules from '../rules/rules';
import Check from '../check/check';

function Index(props) {

    return (
            <>
            <div className="block-content">
                <div className="header">
                    <div className="wrapper1280">
                        <Header />
                    </div>
                </div>
                <div className="prizes">
                    <div className="wrapper1280">
                        <Prizes />
                    </div>
                </div>
                <div className="rules">
                    <div className="wrapper1280">
                        <Rules />
                    </div>
                </div>
                <div className="check" id="check">
                    <div className="wrapper1280">
                        <Check urlServer = {props.urlServer}/>
                    </div>
                </div>
            </div>
            </>
            )
}

export default Index