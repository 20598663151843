import React, { Component, useContext } from 'react';
import IPhone from '../../images/i_phone.png';
import ISupport from '../../images/i_support.png';

const Footer = () => {

    return (
            <div className="footer">
                <div className="wrapper1280">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="footer_phone">
                                <img src={IPhone}/>
                                <div className="footer_phone_text">
                                    <h1>8 938 128 64 44</h1>
                                    <p>Телефон горячей линии</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="footer_support">
                                <img src={ISupport}/>
                                <div className="footer_support_text">
                                    <p>Служба поддержка акции: </p>
                                    <a href="mailto:info@parfums-constantine-promo.ru">info@parfums-constantine-promo.ru</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="footer_files">
                                <a href="/images/rules.pdf" target="_blank"><p>Правила Акции</p></a>
                                <a href="/images/faq.pdf" target="_blank"><p>Вопрос-ответ</p></a>
                                <a href="/images/winners.pdf" target="_blank"><p>Победители</p></a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <p className="footer_copyright">Copyright ©2024</p>
                        </div>
                    </div>
                </div>
            </div>
            )
}

export default Footer
