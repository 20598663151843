import React, { Component, useState } from 'react';
import Pl from '../../images/PL_logo.png';
import Marafett from '../../images/marafett_logo.png';
import Pc from '../../images/PC_logo.png';
import Hb from '../../images/header_border.png';

const Menu = () => {
    const isMobile = window.innerWidth <= 1023;

    if (isMobile) {
        return (
                <div className="menu">
                    <div className="wrapper1280">
                        <div className="row">
                            <div className="col-lg-6">
                                <a href="https://www.parfum-lider.ru/" target="_blank"><img src={Pl} className="header-logo-pl"/></a>
                                <img src={Hb}/>
                                <a href="https://marafett.ru/"  target="_blank"><img src={Marafett} className="header-logo-marafett"/></a>
                            </div>
                            <div className="col-lg-6">
                                <img src={Pc} className="header-logo-pc"/>
                            </div>
                        </div>
                    </div>
                </div>
                );
    } else {
        return (
                <div className="menu">
                    <div className="wrapper1280">
                        <div className="row">
                            <div className="col-md-6">
                                <a href="https://www.parfum-lider.ru/" target="_blank"><img src={Pl} className="header-logo-pl"/></a>
                                <img src={Hb}/>
                                <a href="https://marafett.ru/"  target="_blank"><img src={Marafett} className="header-logo-marafett"/></a>
                            </div>
                            <div className="col-md-6">
                                <img src={Pc} className="header-logo-pc"/>
                            </div>
                        </div>
                    </div>
                </div>
                );
    }
};

export default Menu


