import React, { Component, useState } from 'react';
import Step1 from '../../images/rules_step_1_img.png';
import Step2 from '../../images/rules_step_2_img.png';
import Step3 from '../../images/rules_step_3_img.png';

const Rules = () => {

    return (
            <div className="row">
                <h1>Как выиграть?</h1>
                <div className="col-lg-4">
                    <div className="rules_step">
                        <img src={Step1}/>
                        <p>Купить продукцию бренда 
                            <br/>Parfums Constantine 
                            <br/><span className="color">на 1 500 рублей</span></p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="rules_step">
                        <img src={Step2}/>
                        <p>Зарегистрировать чек 
                            <br/><span>с 12 февраля по 31 марта 2024 г.</span></p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="rules_step">
                        <img src={Step3}/>
                        <p>Получить шанс 
                            <br/>выиграть призы!</p>
                    </div>
                </div>
            </div>
            )
}

export default Rules