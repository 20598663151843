import React, { Component } from 'react';
import Iphone from '../../images/iphone_15.png';
import AppleWatch from '../../images/apple_watch.png';
import AppleAirPods from '../../images/apple_airpods.png';
import Bohemia from '../../images/bohemia.png';

const Prizes = () => {

    return (
            <div className="row">
                <h1>ПРИЗЫ</h1>
                <div className="col-lg-3">
                    <div className="prizes_step">
                        <img src={Iphone}/>
                        <p>iPhone 15</p>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="prizes_step">
                        <img src={AppleWatch}/>
                        <p>Apple Watch</p>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="prizes_step">
                        <img src={AppleAirPods}/>
                        <p> Apple AirPods</p>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="prizes_step">
                        <img src={Bohemia}/>
                        <p>Ароматы Bohemia</p>
                    </div>
                </div>
            </div>
            );
}

export default Prizes